.container {
  width: 100%;
  overflow: hidden;
}

.game-type {
  flex-flow: wrap;
  width: 100%;
  display: flex;
}

.game-type .item {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1.18rem;
  margin-bottom: .3rem;
  margin-right: .16rem;
  display: flex;
}

.game-type .item:nth-child(5n) {
  margin-right: 0;
}

.game-type .item > span {
  color: #fff;
  text-overflow: ellipsis;
  text-align: center;
  width: 1.28rem;
  height: .3rem;
  margin-top: .2rem;
  font-size: .28rem;
  font-weight: bold;
  line-height: .3rem;
  display: block;
  overflow: hidden;
}

.game-type .item > img {
  border-radius: .2rem;
  width: 1rem;
  height: 1rem;
}

.s-title {
  flex-direction: row;
  justify-content: space-between;
  height: .5rem;
  margin-top: .3rem;
  margin-bottom: .2rem;
  padding: 0 .3rem;
  display: flex;
}

.s-title > span {
  color: #292f52;
  font-size: .34rem;
}

.s-title > a {
  width: .36rem;
  height: .36rem;
  display: block;
}

.s-title img {
  width: .36rem;
  height: .36rem;
  position: absolute;
  transform: rotate(-180deg);
}

.list-search {
  min-height: 56vh;
}

.box-list2 .items {
  width: 2.12rem;
}

.box-list2 .items img {
  border-radius: .2rem;
  width: 2.12rem;
  height: 2.12rem;
  box-shadow: -2px 2px 3px 1px #0000004d;
}

.box-list2 .items p {
  text-align: center;
  color: #9b6043;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 100%;
  height: .4rem;
  font-size: .28rem;
  line-height: .4rem;
  overflow: hidden;
}

.box-list3 {
  flex-flow: wrap;
  display: flex;
}

.box-list3 .item {
  background-color: #fff;
  border-radius: .14rem;
  flex-direction: row;
  align-items: center;
  width: 3.34rem;
  margin-bottom: .3rem;
  margin-left: .3rem;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 3px #0000004d;
}

.box-list3 .item .star {
  flex-direction: row;
  align-items: center;
  width: 100%;
  display: flex;
}

.box-list3 .item .star img {
  width: .2rem;
  height: .2rem;
  margin-right: .04rem;
}

.box-list3 .item > a {
  display: flex;
}

.box-list3 .item > a > img {
  width: 1.6rem;
  height: 1.6rem;
}

.box-list3 .item .info {
  flex-direction: column;
  justify-content: center;
  margin-left: .18rem;
  display: flex;
}

.box-list3 .item .info > img {
  width: 90%;
  margin-bottom: .1rem;
}

.box-list3 .item .info > .txt {
  color: #2c2350;
  word-break: break-word;
  text-overflow: ellipsis;
  width: 100%;
  height: .6rem;
  font-size: .2rem;
  overflow: hidden;
}

.box-list3 .item .info .score {
  color: #ff0059;
  margin-top: .1rem;
  font-size: .24rem;
}

.popular {
  width: 100%;
}

.popular .title {
  color: #000;
  width: 100%;
  margin: 0 auto .3rem;
  padding-left: .16rem;
  font-size: .34rem;
}

.recent-index {
  flex-direction: row;
  width: 100%;
  display: flex;
  overflow: scroll;
}

.recent-index > .item {
  width: 1.26rem;
  margin-right: .2rem;
  position: relative;
}

.recent-index > .item > a {
  flex-direction: column;
  width: 1.26rem;
  display: flex;
}

.recent-index > .item > a img {
  border: .04rem solid #292f52;
  border-radius: .1rem;
  width: 1.26rem;
  height: 1.26rem;
}

.recent-index > .item > a p {
  text-align: center;
  color: #292f52;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 100%;
  height: .4rem;
  margin-top: .14rem;
  font-size: .28rem;
  line-height: .4rem;
  overflow: hidden;
}

.recent-index > .item > a p > .txt {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
}

.recent-list > .item {
  margin-top: 0;
  margin-bottom: .3rem;
}

.swiper {
  border-radius: .3rem;
  width: 100%;
  padding-top: .4rem;
}

.swiper-slide {
  text-align: center;
  background: #fff;
  border-radius: .3rem;
  justify-content: center;
  align-items: center;
  width: 5.62rem;
  font-size: 18px;
  transition: all .3s;
  display: flex;
  overflow: hidden;
  transform: scale(.9);
}

.swiper-slide-active, .swiper-slide-duplicate-active {
  z-index: 2;
  transform: scale(1);
}

.popularWrapper .swiper-slide:before {
  content: "";
  width: .6rem;
  height: .6rem;
  position: absolute;
  top: .1rem;
  left: .1rem;
}

.swiper-slide a {
  width: 5.62rem;
  display: inline-block;
}

.swiper-slide a img {
  object-fit: cover;
  border-radius: .3rem;
  width: 5.62rem;
  height: 4.22rem;
  display: block;
  overflow: hidden;
}

.swiper-bt {
  background-color: #0000004d;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem;
  width: 100%;
  height: .84rem;
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.swiper-bt > .s-tt {
  color: #fff;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-left: .3rem;
  font-size: .32rem;
  font-weight: bold;
  display: flex;
}

/*# sourceMappingURL=search.bc739848.css.map */
