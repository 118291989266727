@import url("../css/common.css");

.container{
    width:100%;
    overflow: hidden;
}

.game-type{
    width:100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width:1.18rem;
        margin-right:0.16rem;
        margin-bottom:0.3rem;
     }
     .item:nth-child(5n){
        margin-right:0;
     }
     .item>span{
        display: block;
        color:#fff;
        font-size:0.28rem;
        font-weight: bold;
        margin-top:0.2rem;
        width:1.28rem;
        height:0.3rem;
        line-height: 0.3rem;
        overflow: hidden;
        text-overflow: ellipsis ;
        text-align: center;
     }
     .item>img{
        width:1rem;
        height:1rem;
        border-radius: 0.2rem;
     }
 }


.s-title{

    height:0.5rem;
    margin-bottom:0.2rem;
    margin-top:0.3rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:0 0.3rem;
    &>span{
        font-size:0.34rem;
        color:#292F52;
    }
    &>a{
        display: block;
        width:0.36rem;
        height:0.36rem;
    }
    img{
        width:0.36rem;
        height:0.36rem;
        position: absolute;
        transform: rotate(-180deg);
    }
}
.list-search{
    min-height: 56vh;
}


.box-list2 .items{
    width:2.12rem;
    img{
        width:2.12rem;
        height:2.12rem;
        border-radius: 0.2rem;
        box-shadow: -2px 2px 3px 1px rgba(0,0,0,0.3);
    }
    p{
        width:100%;
        height:0.4rem;
        line-height:0.4rem;
        text-align: center;
        color:#9B6043;
        font-size:0.28rem;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break:break-all;
    }
}
.box-list3{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item{
        width:3.34rem;
        margin-bottom:0.3rem;
        margin-left:0.3rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: #fff;
        border-radius:0.14rem ;
        box-shadow: 0px 0 3px 0px rgba(0,0,0,0.3);
        overflow: hidden;
        .star{
            width:100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            img{
                width:0.2rem;
                height:0.2rem;
                margin-right:0.04rem;
            }
        }
        &>a{
            display: flex;
        }
        &>a>img{
            width:1.6rem;
            height: 1.6rem;;
        
        }
        .info{
            display: flex;
            flex-direction: column;
            margin-left:0.18rem;
            justify-content:center;
            &>img{
                width:90%;
                margin-bottom:0.1rem;
            }
            &>.txt{
                width:100%;
                font-size:0.2rem;
                height:0.6rem;
                color:#2C2350;
                word-break:break-word;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .score{
                color:#FF0059;
                font-size:0.24rem;
                margin-top:0.1rem;
            }
        }
    }
}

.popular{
    width:100%;
    .title{
        width:100%;
        font-size:0.34rem;
        color:#000;
        margin:0 auto;
        padding-left:0.16rem;
        margin-bottom: 0.3rem;
    }
}
.recent-index{
    width:100%;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    &>.item{
        width:1.26rem;
        position: relative;
        margin-right:0.2rem;
        &>a{
            width:1.26rem;
            display: flex;
            flex-direction: column;
            img{
                width:1.26rem;
                height:1.26rem;
                border-radius: 0.1rem;
                border:0.04rem solid #292F52;
            }
            p{
                width:100%;
                height:0.4rem;
                line-height:0.4rem;
                text-align: center;
                color:#292F52;
                font-size:0.28rem;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break:break-all;
                margin-top:0.14rem;
                &>.txt{
                    width:100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }
}
.recent-list{
    &>.item{
        margin-top:0;
        margin-bottom: 0.3rem;
    }
}

.swiper {
    width: 100%;
    padding-top:0.4rem;
    border-radius: 0.3rem;
}
.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    width: 5.62rem;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    transition: 300ms;
    transform: scale(0.9);
    border-radius: 0.3rem;
    overflow: hidden;
  }
  .swiper-slide-active,.swiper-slide-duplicate-active{
    transform: scale(1);
    z-index: 2;
  }
.popularWrapper .swiper-slide::before{
    content: "";
    position: absolute;
    width:0.6rem;
    height:0.6rem;
    top:0.1rem;
    left:0.1rem;

}
.swiper-slide a{
    width: 5.62rem;
    display: inline-block;
}
.swiper-slide a img{
    display: block;
    width: 5.62rem;
    height:4.22rem;
    object-fit: cover;
    border-radius: 0.3rem;
    overflow: hidden;
    
}
.swiper-bt{
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    height:0.84rem;
    background-color: rgba(0,0,0,0.3);
    overflow: hidden;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    &>.s-tt{
        width:100%;
        height: 100%;
        font-size:0.32rem;
        color:#fff;
        font-weight: bold;
        display: flex;
        align-items: center;
        padding-left:0.3rem;
    }
}

